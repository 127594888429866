<template>
    <div class="toolbar-container-filter d-inline-flex mr-20">
        <Button label="Filtros" icon="pi pi-filter" text @click="toggleShowFiltros"/>
    </div>
    <div class="toolbar-container">
        <div class="d-inline-block align-items-center pdl-10 pdr-10">          
        <b><label>Buscar&nbsp;</label></b>
        </div>
        <div class="d-inline-block align-items-center">
        <InputText id="filterCard" v-model="filterCard" @keydown.enter="setParams()" placeholder="" type="text" class="w-full md:w-15rem sm:w-auto custom-input" />
        </div>
    </div>
    <OverlayPanel ref="showfiltros" >
        <div class="confirmation-content">
            <i class="pi pi-search mr-3" style="font-size: 2rem" />
            <b><label>BUSQUEDA AVANZADA</label></b><br><br><br>
            <div class="field flex flex-wrap gap-5 mb-5" v-if="general">
                <div class="align-items-center">          
                    <b><label>Tablero:</label></b>
                </div>
                <div class="align-items-center">            
                    <Dropdown v-model="tablero_id" :options="tableros" optionLabel="nombre" optionValue="id" placeholder="Seleccione" class="w-full md:w-15rem" :showClear=true  @change="filtroTablero()" />
                </div>
            </div>            
            <div class="field flex flex-wrap gap-5 mb-5">
                <div class="align-items-center">          
                    <b><label>Buscar:</label></b>
                </div>
                <div class="flex align-items-center gap-2">
                    <InputText id="filterCard" v-model="filterCard" @keydown.enter="setParams()" placeholder="Texto" type="text" class="w-full md:w-15rem sm:w-auto" />
                </div>
            </div>
            <div class="field flex flex-wrap gap-5 mb-5">
                <div class="align-items-center">          
                    <b><label>Asignadas a:</label></b>
                </div>
                <div class="align-items-center">            
                    <MultiSelect v-model="users_ids" :options="this.usuarios" filter optionLabel="name" optionValue="id" placeholder="Usuario" class="w-full md:w-15rem" @change="setParams()"/>
                </div>
            </div>
            <div class="field flex flex-wrap gap-5 mb-5">
                <div class="align-items-center">          
                    <b><label>Usando:</label></b>
                </div>
                <div class="align-items-center">            
                    <Dropdown v-model="categoria_id" :options="categorias" optionLabel="nombre" optionValue="id" placeholder="Categoría" class="w-full md:w-15rem" :showClear=true  @change="filtroCategoria()" />
                </div>
                <div class="align-items-center">            
                    <Dropdown v-model="ramo_id" :options="ramos" optionLabel="nombre" optionValue="id" placeholder="Ramo" class="w-full md:w-15rem" :showClear=true  @change="filtroRamo()" />
                </div>
                <div class="align-items-center">            
                    <Dropdown v-model="subramo_id" :options="subramos" optionLabel="nombre" optionValue="id" placeholder="Subramo" class="w-full md:w-15rem" :showClear=true  @change="filtroSubramo()"/>
                </div>
            </div>
            <div class="field flex flex-wrap gap-5 mb-5">
                <div class="align-items-center">          
                    <b><label>Filtrar por:</label></b>
                </div>
                <div class="align-items-center">
                    <RadioButton v-model="filtro" inputId="filtro" name="filtro" value="responsable" @change="setParams()" />
                    <label for="Responsable" class="ml-2">Responsable</label>
                </div>
                <div class="align-items-center">
                    <RadioButton v-model="filtro" inputId="filtro" name="filtro" value="observador" @change="setParams()" />
                    <label for="Observador" class="ml-2">Observador</label>
                </div>
                <div class="align-items-center">
                    <RadioButton v-model="filtro" inputId="filtro" name="filtro" value="todas" @change="setParams()" />
                    <label for="Todas" class="ml-2">Todas</label>
                </div>
            </div>
            <div class="field flex flex-wrap gap-5 mb-5">
                <div class="align-items-center">          
                    <b><label>Ordenar por:</label></b>
                </div>
                <div class="align-items-center">            
                    <Checkbox v-model="xprioridad" :binary="true" inputId="xprioridad" name="xprioridad" @change="setParams()"/>
                    <label for="xprioridad" class="ml-2"> Prioridad </label>
                </div>          
                <div class="align-items-center">
                    <RadioButton v-model="ordenar" inputId="ordenar" name="ordenar" value="tiempo" @change="setParams()" />
                    <label for="Tiempo" class="ml-2">Tiempo</label>
                </div>
                <div class="align-items-center">
                    <RadioButton v-model="ordenar" inputId="ordenar" name="ordenar" value="recientes" @change="setParams()" />
                    <label for="Recientes" class="ml-2">Recientes</label>
                </div>
            </div>
            <div class="field flex flex-wrap gap-5 mb-5">
                <b><label>Mostrar:</label></b>
                <div class="align-items-center">            
                    <Checkbox v-model="xestatus" :binary="true" inputId="xestatus" name="xestatus" @change="setParams()"/>
                    <label for="xestatus" class="ml-2"> Bloqueadas </label>
                </div>
            </div>
                <!--<div class="field flex flex-wrap gap-5 mb-5">
                <b><label>Terminadas entre:</label></b>
                <div class="align-items-center">
                <Calendar v-model="dates" selectionMode="range" :manualInput="false" dateFormat="dd/mm/yy" :showButtonBar=true :showIcon=true @hide="filterDates"/>
                </div>
                </div>-->
        </div>
        
        
        <template #footer>
            <Button label="Cerrar" icon="pi pi-times" text @click="searchDialog = false"/>
        </template>
    </OverlayPanel> 
</template>
<script>
import { sendRequestList } from '@/helper';

const url = process.env.VUE_APP_BASE_URL;

export default {
    emits: ['params'],
    props: {
        general: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            searchDialog: false,
            params: "",
            filterCard: "",
            users_ids: [],
            usuarios: null,
            ramo_id: '',
            subramo_id: '',
            categoria_id: '',
            tablero_id: '',
            ramos: [],
            subramos: [],
            categorias: [],
            tableros: [],
            filtro: "todas",
            ordenar: "tiempo",
            xprioridad: true,
            xestatus: false,
            fechaInicio: "",
            fechaFin: "",
        }
    },
    methods: {
        showSearchDialog() {
            this.searchDialog = true
        },
        setParams() {
            const filter_card = encodeURIComponent(this.filterCard);
            var estatus = "";
            var filter_users = [];
            if(this.xestatus == true) {
                estatus = "bloqueada";
            }
            if(this.users_ids.length > 0) {
                filter_users= this.users_ids;
            }            

            this.params = "?filtro="+this.filtro+
                "&xprioridad="+this.xprioridad+
                "&orden="+this.ordenar+
                "&texto="+filter_card+
                "&estatus="+estatus+
                "&usuarios="+filter_users+
                "&ramo="+this.ramo_id+
                "&subramo="+this.subramo_id+
                "&categoria="+this.categoria_id+
                "&tablero="+this.tablero_id+
                "&fechainicio="+this.fechaInicio+
                "&fechafin="+this.fechaFin;

            this.$emit('params', { params: this.params });
        },
        async getUsuarios(){
            this.loading = true;
            const result = await sendRequestList(url+"/userslist");
            this.usuarios = result.data.data;
        },
        async getRamos(){
            this.loading = true;
            const result = await sendRequestList(url+"/ramoslist");
            this.ramos = result.data.data;        
        },
        async getSubRamos(){
            this.loading = true;
            const result = await sendRequestList(url+"/subramoslist?ramo_id="+this.ramo_id);
            this.subramos = result.data.data;        
        },
        async getCategorias() {
            const result = await sendRequestList(url+"/categoriaselect");
            this.categorias = result.data;            
        },
        async getTableros(){
          const result = await sendRequestList(url+"/tableroslist");
          this.tableros = result.data.data;
        },
        filtroRamo() {
            if (this.ramo_id == null) {
            this.ramo_id = '';
            this.subramo_id = '';
            this.subramos = [];
            } else {
            this.getSubRamos();
            }
            this.setParams();
            
        },
        filtroSubramo() {
            if (this.subramo_id == null) {
            this.subramo_id = '';
            }
            this.setParams();
        },
        filtroCategoria() {
            if (this.categoria_id == null) {
                this.categoria_id = '';
            }
            
            this.setParams();
        },
        filtroTablero() {
            if (this.tablero_id == null) {
                this.tablero_id = '';
            }
            
            this.setParams();
        },
        filterDates() {
          if (this.dates) {
            this.fechaInicio = this.fechaFormateada(this.dates[0]);
            this.fechaFin = this.fechaFormateada(this.dates[1]);
          } else {
            this.fechaInicio = "";
            this.fechaFin = "";
          }

          this.setParams();
        },
        fechaFormateada(fecha) {
          const anio = fecha.getFullYear();
          const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
          const dia = fecha.getDate().toString().padStart(2, '0');            
            
          return `${dia}/${mes}/${anio}`;
        },
        toggleShowFiltros(event){
            this.$refs.showfiltros.toggle(event);
        },
    },
    mounted() {
        this.getUsuarios();
        this.getRamos();
        this.getSubRamos();
        this.getCategorias();
        if (this.general) {
            this.getTableros();
        }        
    },
    watch: {
        filter() {
            this.filterCard = this.filter;
        }
    }
}
</script>
<style scoped>
    .toolbar-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 7.8px 10px;
    min-height: 3.2rem;
    }
   .toolbar-container-filter {
    background-color: #fff;
    border-radius: 10px;
    padding: 7px 10px;
    min-height: 3.2rem;
  }
  .d-inline-flex {
    display: inline-flex;
  }

  .mr-20 {
    margin-right: 20px;
  }

  .pdl-10 {
    padding-left: 10px;
  }

  .pdr-10 {
    padding-right: 10px;
  }

  .search-container {
    padding-left: 1rem;
  }
  .d-inline-block {
    display: inline-block;
  }

  .d-inline-flex {
    display: inline-flex;
  }
  .p-button {
    padding: 4px 4px;
    text-decoration: none !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }
</style>